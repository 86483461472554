@import "../../../../styles/mainStyles.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  @include xs {
    gap: 1rem;
  }

  .warning {
    margin: 0 auto;
    width: 80%;
  }

  .title {
    @include MediumFont;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 104%;
    letter-spacing: 0em;
    text-align: center;

    @include xs {
      font-size: 1.25rem;
    }
  }

  .paymentMethodContainer {
    display: flex;
    flex-direction: column;
    gap: .5rem;

    .paymentMethod {
      padding: 1px;
      flex-wrap: wrap;
      width: 100%;
      height: 100%;
      background: $gray-fifty;
      border-radius: .675rem;

      .box {
        display: flex;
        align-items: center;
        gap: 1rem;
        padding: 1.25rem;
        border-radius: .625rem;
        width: auto;
        height: auto;

        background: $bg-color;
        z-index: 500;

        @include xs {
          padding: 1rem;
          height: fit-content;
        }

        .circle {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 3.125rem;
          height: 3.125rem;
          border-radius: 100%;

          @include s {
            width: 3rem;
            height: 3rem;
          }
          @include xs {
            width: 2.5rem;
            height: 2.5rem;
          }
        }
        .textContainer {
          display: flex;
          flex-direction: column;
          gap: .5rem;
          background: $bg-color;
          @include s {
            gap: .25rem;
          }
          .name {
            @include MediumFont;
            font-size: 1.25rem;
            font-weight: 500;
            line-height: 100%;
            letter-spacing: 0em;
            text-align: left;

            @include xs {
              font-size: 1rem;
            }
          }
          .description {
            @include RegularFont;
            font-size: 1rem;
            font-weight: 500;
            line-height: 100%;
            letter-spacing: 0em;
            text-align: left;
            color: $gray-fifty;

            @include xs {
              font-size: .875rem;
            }
          }
        }
        .arrow {
          margin-left: auto;
          transform: rotate(90deg);

          path {
            fill: $gray-fifty;
          }
        }
      }

      &.disable, &.comingSoon {
        cursor: not-allowed !important;
        background: $gray-fifty;
        .box {
          color: $gray-fifty !important;
          .circle {
            background: $gray-fifty;
          }
        }
      }

      &:hover {
        cursor: pointer;
        background: linear-gradient(rgb(0 0 0/5%) 0 0);

        .box {
          background-image: linear-gradient(rgb(0 0 0/5%) 0 0);
          .textContainer {
            background-image: linear-gradient(rgb(0 0 0/5%) 0 0);
          }
        }
      }
    }
    .binancePay {
      .description {
        color: $primary-color !important;
        &.disable {
          color: $gray-fifty !important;
        }
      }
    }
    .myWallet {
      background: $primary-gradient;
      .box {
        background: $bg-color;
        .textContainer {
          background: $bg-color;
        }
      }
    }
    .linkDescription {
      display: flex;
      gap: .25rem;
      font-size: .75rem;
      font-weight: 400;
      line-height: 100%;
      letter-spacing: 0em;
      text-align: left;
      color: $gray-fifty;

      span {
        text-decoration: underline;
        &:hover {
          cursor: pointer;
          color: $gray;
          font-weight: 600;
         }
      }
    }
    .or {
      font-size: 1rem;
      font-weight: 400;
      line-height: 100%;
      letter-spacing: 0em;
      text-align: center;
      color: $gray-fifty;
      padding-bottom: .75rem;
      @include s {
        font-size: .875rem;
        padding-bottom: .5rem;
      }
    }
  }
}

.bankIcon {
  background: $orange;
  svg {
    width: 1.75rem;
    @include s {
      width: 1.5rem;
    }
    @include xs {
      width: 1.375rem;
    }
  }
}
.cardIcon {
  background: $blue-light;
  svg {
    width: 1.75rem;
    @include s {
      width: 1.5rem;
    }
    @include xs {
      width: 1.375rem;
    }
  }
}
.cardIcons {
  display: flex;
  gap: .5rem
}
.binanceIcon {
  background: $primary-color;
  svg {
    width: 1.75rem;
    @include s {
      width: 1.5rem;
    }
    @include xs {
      width: 1.375rem;
    }
  }
}
.walletIcon {
  background: $secondary-color;
  svg {
    path {
      fill: $white;
    }
    @include s {
      width: 1.5rem;
    }
    @include xs {
      width: 1.375rem;
    }
  }
}