@import "../../../../../../styles/mainStyles.scss";

.container {
  padding: 2.5rem 0 1.5rem;
  gap: 1rem;
}

.title {
  @include MediumFont;
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 104%;
  letter-spacing: 0em;
  text-align: center;
  white-space: nowrap;

  @include s {
    font-size: 1.75rem;
  }
  @include xs {
    font-size: 1.25rem;
  }
}

.stripe {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.875rem;
  gap: 10px;

  color: $light;
  font-size: 2rem;

  @include s {
    padding: 1.5rem .5rem;
  }
  @include xs {
    padding: 1.5rem 0;
  }
}