@import '../../styles/mainStyles.scss';

.onBoarding {
  display: none;
  width: 100%;
  max-width: 15.625rem;
  height: auto;
  padding: 0.75rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.0625rem;
  flex-shrink: 0;
  position: absolute;
  top: -3rem;
  left: 25%;
  background: $secondary-color;
  z-index: 999;
  pointer-events: all;
  -webkit-animation: fadeIn .6s;
  animation: fadeIn .6s;

  &.visible {
    display: flex;
  }

  > span {
    @include MediumFont;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 0.9rem */
    color: setColor($secondary-color);
  }

  .footer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    span {
      @include MediumFont;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: 104%; /* 0.78rem */
      color: $light-fifty;
    }
    .buttons {
      display: flex;
      gap: .5rem;
      align-items: center;

      span {
        color: $ternary-color;
        &:first-of-type,
        &:first-child {
          color: setColor($secondary-color);
          font-weight: 400;
        }
        &:last-child {
          font-weight: 600;
          color: $primary-color;
        }
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}