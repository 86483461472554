@import "../../styles/mainStyles.scss";

.counter {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .25rem;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  .count {
    @include MediumFont;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: 0em;
    text-align: center;
    height: 1.375rem;
    width: fit-content;
    min-width: 5rem;
    max-width: 8rem;
    padding: 0;
    margin: 0;

    border: none;
    outline: none;

    @include s {
      font-size: 1rem;
      min-width: 4rem;
      max-width: 7rem;
      height: 1.25rem;
      max-height: 1.25rem;
    }
    @include xs {
      font-size: .875rem;
      min-width: 2.75rem;
      max-width: 5rem;
      height: 1rem;
      max-height: 1rem;
    }
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  svg {
    &:hover {cursor: pointer;}

    @include s {
      width: 1.25rem;
      height: 1.25rem;
    }
    @include xs {
      width: 1rem;
      height: 1rem;
    }
  }
}