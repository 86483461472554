@import "../../styles/mainStyles.scss";

.section {
  position: relative;
  background-image: url('../../assets/images/banner-background.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  height: 673px;
  @include m {
    height: 600px;
    text-align: center;
  }
  @include s {
    height: 500px;
  }
  @include xs {
    background-image: url('../../assets/images/banner-background-mobile.jpg');
    height: 350px;
  }
}

.container {
  align-items: flex-start;
  gap: 1.75rem;
  width: 100%;
  margin: 7rem auto auto;

  &.second {
    align-items: center;
    justify-content: center;
    gap: 2.5rem;
    margin: auto auto;
  }

  @include m {
    margin-top: 6rem;
  }
  @include s {
    margin-top: 5rem;
    gap: 1.5rem;
  }
  @include xs {
    margin-top: 3.5rem;
  }
}

.title {
  @include BoldFont;
  font-size: 6rem;
  font-weight: 700;
  color: $light;
  line-height: 100%;
  letter-spacing: -0.01em;
  text-align: left;

  @include m {
    font-size: 5rem;
    line-height: 5rem;
  }
  @include s {
    font-size: 4rem;
    line-height: 4rem;
  }
  @include xs {
    font-size: 3rem;
    line-height: 3rem;
  }

  span {
    color: $primary-color;
  }

  @include m {
    font-size: 5rem;
  }
  @include s {
    font-size: 3.5rem;
  }
  @include xs {
    font-size: 2.5rem;
  }
}

.subtitle {
  @include RegularFont;
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 105%;
  color: $light;
  width: 60%;

  &.second {
    text-align: center;
    width: 100%;
  }

  @include m {
    font-size: 2.25rem;
    width: 80%;
  }
  @include s {
    font-size: 2rem;
    width: 80%;
  }
  @include xs {
    font-size: 1.25rem;
    width: 100%;
  }
}