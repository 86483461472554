@import '../../styles/mainStyles.scss';

.container {
  display: flex;
  gap: .5rem;
  justify-content: center;
  align-items: flex-start;
  border: 1px solid;
  border-radius: .625rem;
  padding: 1.125rem 1.75rem;

  @include xs {
    padding: .875rem 1rem;
  }

  svg {
    transform: scale(1.2);
    min-width: .861rem;
  }

  &.error {

  }
  &.warning {
    border-color: $primary-color;
    background: rgba(255, 176, 0, 0.1);
  }
  &.info {
    border-color: $blue;
    background: rgba(80, 81, 249, 0.10);
  }

  .text {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    text-align: left;

    .label {
      @include BoldFont;
      font-size: .75rem;
      font-weight: 700;
      line-height: .75rem;
      letter-spacing: 0em;
    }
    .description {
      font-size: .75rem;
      font-weight: 400;
      line-height: .75rem;
      letter-spacing: 0em;
    }
  }
}