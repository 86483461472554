@import "../../../../styles/mainStyles.scss";

.personalInformation {
  height: auto;
  width: 100%;
  padding: 0;

  .form {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: .75rem;
    // padding: 1.5rem .875rem 2rem;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      span {
        @include MediumFont;
        font-size: 1rem;
        font-weight: 500;
        line-height: 105%;
        letter-spacing: 0em;
        color: $secondary-color;
      }

      .button {
        border: none;
        outline: none;
        background: none;

        @include MediumFont;
        font-size: 1rem;
        font-weight: 500;
        line-height: 105%;
        letter-spacing: 0em;
        color: $gray-fifty;

        &.active {
          color: $green;
        }
        &:hover {
          cursor: pointer;
        }
      }
    }
    .body {
      display: flex;
      flex-direction: column;
      gap: .75rem;
      width: 100%;

      .inputContainer {
        display: flex;
        flex-direction: column;
        width: 100%;

        .label {
          font-size: .75rem;
          font-weight: 400;
          line-height: .75rem;
          letter-spacing: 0em;
          text-align: left;
          color: $gray-fifty;
        }
        .box {
          display: flex;
          position: relative;
          .input {
            display: flex;
            justify-content: space-between;
            flex-grow: 1;
            padding: 0 1.25rem .25rem 0;
            @include MediumFont;
            font-size: 1rem;
            font-weight: 600;
            line-height: 100%;
            letter-spacing: 0em;
            text-align: left;

            border: none;
            outline: none;
            border-bottom: 1px solid #43435180;

            &:disabled {
              background: none;
            }
          }
          svg {
            position: absolute;
            right: 0;
            width: 20px;
            height: 20px;
            path {
              fill: $light;
            }

            &.active {
              &:hover {
                cursor: pointer;
              }
              path {
                fill: $gray;
              }
            }
          }
        }
      }
    }
  }
}