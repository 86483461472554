@import "../../styles/mainStyles.scss";

.section {
  padding: 1.5rem 0;
}
.container {
  width: 100%;
  gap: 1rem;

  @include s {
    padding: 1rem;
  }
  @include xs {
    padding: 1rem .5rem;
  }
}
.firstColumn, .secondColumn {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.firstColumn {
  flex-grow: 1;
}

.transactionsCard{
  flex-direction: column;
  padding: 1.5rem;
  width: stretch;
}

.secondColumn {
  width: 32%;
  @include m {
    width: 100%;
  }
}
.hideMobile {
  @include m {
    display: none;
  }
}