@import '../../styles/mainStyles.scss';

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .825rem;
  height: 2.5rem;
  min-height: 2.5rem;
  border-radius: $default-button-border-radius;
  padding: 0 1rem;

  box-shadow: none;
  border: none;
  border-color: transparent;

  @include MediumFont;
  font-size: .875rem;
  font-weight: 500;
  line-height: 100%;
  span {
    @include MediumFont;
    font-size: .875rem;
    font-weight: 500;
    @include xs {
      font-size: .75rem;
    }
  }
  @include xs {
    font-size: .75rem;
    @include MediumFont;
    font-weight: 500;
  }

  &:hover {
    cursor: pointer;
    border: none;
    box-shadow: none;
    outline: none;
    opacity: .9;
  }
}
.button-outline {
  background: $bg-color;
  color: $text-color;
  @include MediumFont;
  font-size: .875rem;
  font-weight: 500;
  box-shadow: inset 0 0 0 1px $neutrals5;

  &:hover {
    box-shadow: none;
    background-image: linear-gradient(rgb(0 0 0/5%) 0 0);
  }
}
.circle {
  border-radius: 50%;
  padding: 0;
  min-width: 3.5rem;
  min-height: 3.5rem;
}
.background {
  &-primary {
    color: $light;
    background: $primary-color;
  }
  &-primary-gradient {
    color: $light;
    background: $primary-gradient;
  }
  &-secondary {
    color: $light;
    background: $secondary-color;
  }
  &-light {
    background: $light;
  }
  &-white {
    background: white;
  }
  &-transparent {
    background: transparent;
  }
}
.border {
  &-primary {
    box-shadow: inset 0 0 0 1px $primary-color;
  }
  &-secondary {
    box-shadow: inset 0 0 0 1px $secondary-color;
  }
  &-light {
    box-shadow: inset 0 0 0 1px $light;
  }
  &-white {
    box-shadow: inset 0 0 0 1px white;
  }
  &-transparent {
    box-shadow: inset 0 0 0 1px transparent;
  }
  &-dark {
    box-shadow: inset 0 0 0 1px $dark-light;
    &:hover {
      box-shadow: inset 0 0 0 1px $dark-light;
    }
  }
}
.loading {
  opacity: .8;
  &:hover {
    cursor: not-allowed;
    opacity: .7;
  }
}
.disabled {
  color: $light-fifty;
  background: $gray-fifty;
  &:hover {
    cursor: not-allowed;
    opacity: unset;
  }
}