@import "../../../../styles/mainStyles.scss";

.myWallet {
  display: flex;
  flex-direction: column;
  height: auto;
  width: stretch;
  border: 1px solid $primary-color;
  position: relative;
  overflow: visible;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    width: stretch;
    border-bottom: 1px solid $primary-color;

    @include xs {
      padding: .875rem;
    }
    > span {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      @include MediumFont;
      font-size: 1rem;
      font-weight: 500;
      line-height: 105%;
      letter-spacing: 0em;
      text-align: left;
      @include xs {
        font-size: .875rem;
      }
    }

    .address {
      display: flex;
      align-items: center;
      gap: .25rem;
      margin-left: auto;
      width: fit-content;

      &:hover {
        cursor: pointer;
      }

      @include s {
        font-size: .875rem;
      }
      @include xs {
        font-size: .75rem;
      }
      svg {
        path {
          fill: $primary-color;
        }
      }
    }
  }
  .row {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    padding: 1.25rem 1rem;
    justify-content: space-between;
    align-items: center;
    width: stretch;
    background: $primary-color;
    border-bottom-left-radius: .5rem;
    border-bottom-right-radius: .5rem;

    &.loading {
      background: $neutrals6;
    }
    @include s {
      justify-content: center;
    }
  }
  .assets {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 45%;
    width: 50%;
    @include s {
      width: 100%;
      flex-grow: 1;
    }

    .column {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: .25rem;
      padding: 0 .5rem;
      color: setColor($primary-color);

      width: 50%;
      @include s {
        &:last-child {
          width: 60%;
        }
      }
      @include xs {
        &:last-child {
          width: 55%;
        }
      }

      .text {
        @include RegularFont;
        font-size: .75rem;
        font-weight: 400;
        line-height: 100%;
        letter-spacing: 0em;
        white-space: nowrap;
      }
      .value {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: .5rem;
        @include MediumFont;
        font-size: 2.5rem;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: 0em;
        width: auto;

        &.loading {
          line-height: normal;
        }
        @include s {
          font-size: 2rem;
        }
        @include xs {
          font-size: 1.625rem;
        }

        > span {
          @include RegularFont;
          font-size: 1rem;
          text-transform: uppercase;
          @include xs {
            &:first-child {
              font-size: .875rem;
            }
            font-size: .75rem;
          }

          &.officialCurrency {
            font-size: .75rem;
            font-weight: 400;
            line-height: 100%;
            letter-spacing: 0em;
          }
        }
      }

      border-left: 2px solid setColor($primary-color);
    }
  }
}
.buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  flex-grow: 1;
  position: relative;

  @include s {
    flex-direction: row;
    justify-content: space-between;
  }

  .button {
    align-items: center;
    border-radius: .625rem;
    padding: 0 2rem;
    width: 35%;
    gap: .25rem;
    height: 2.5rem;

    &:disabled {
      color: $text-color;
      background: $white;
      opacity: .5;
    }

    @include s {
      padding: 0 1rem;
      width: 100%;
      box-shadow: 0 0.25rem 1.25rem rgba(0, 0, 0, 0.1);
    }
    svg {
      min-width: 1.25rem;
      path {
        fill: $text-color;
      }
    }
    span {
      @include MediumFont;
      align-items: center;
      font-size: 1rem;
      font-weight: 500;
      line-height: 105%;
      letter-spacing: 0em;
      color: $text-color;
      @include s {
        font-size: .9rem;
      }
      @include xs {
        font-size: .8rem;
      }
    }
  }
}

.onBoardingWallet {
  left: 20%;
  @include s {
    top: -4.5rem;
    left: 15%;
  }
  @include xs {
    left: 10%;
  }
}
.onBoardingDeposit {
  top: -50%;
  left: -60%;

  @include s {
    top: -5.5rem;
    left: 0rem
  }
}