@import '../../styles/mainStyles.scss';

.section {
  display: block;
  box-shadow: 0 4px 4px $shadow-color;
  background: $header-bg-color;
}

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 1rem;
  gap: 3.5rem;

  @include s {
    gap: 2.5rem;

  }
  @include xs {
    gap: 1.5rem;
  }

  .logo {
    transition: $animation-timing-default;
     &:hover {
      cursor: pointer;
      transform: scale(1.02);
     }
  }
}

.navsRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 2rem;
  margin-left: auto;

  .navItem {
    @include RegularFont;
    font-size: .875rem;
    font-weight: 600;
    &:hover {
      cursor: pointer;
    }
  }
}

.connectButton {
  width: 11rem;
  border-radius: .5rem;
  svg {
    display: none;
    path {
      fill: $light;
    }
  }

  @include s {
    width: auto;
    span {
      display: none;
    }
    svg {
      display: block;
    }
  }
}

.user {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .5rem;
  padding: .325rem .5rem;

  border-radius: .5rem;
  border: 1px solid $gray-fifty;

  @include MediumFont;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25rem;
  letter-spacing: 0em;

  .initials {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.688rem;
    height: 1.688rem;
    background: $primary-color;
    border-radius: 0.313rem;
    color: $white;
    text-transform: uppercase;
  }
  .balance {
    font-size: .875rem;
    line-height: 1.125rem;
    span {
      color: $primary-color;
    }
  }

  &:hover {
    cursor: pointer;
    opacity: .8;
  }
}

.btns {
  display: flex;
  align-items: center;
  gap: 1rem;

  .logout {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 100%;
    background: #d80505c2;
    border: 1px solid $light;
    &:hover {
      cursor: pointer;
      opacity: .9;
    }

    svg {
      width: 1.125em;
      height: 1.125em;
      fill: $light;
    }
  }

  .circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    background: $primary-color;
  }
  .hambur, .circle {
    &:hover {
      cursor: pointer;
      opacity: .9;
    }
  }
}


// TO DELETE ??
.dropdown {
  display: none;
}

.languageDropdownHead {
  height: 3rem;
  width: 3.125rem;
  box-shadow: inset 0 0 0 2px $light-twenty-five;
  padding: 0 2rem 0 .75rem;
}

.languageDropdownArrow {
  right: .75rem;
}