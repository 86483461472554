@import '../../../../styles/mainStyles.scss';

.propertyRow {
  display: flex;
  align-items: center;
  gap: .875rem;

  &.oneLine {
    width: 100%;
    justify-content: space-between;
    .propertyContainer {
      flex-direction: row-reverse;
      flex: auto;
      justify-content: space-between;
      align-items: center;
      .label {
        font-size: 1.25rem;
        line-height: 100%;
        @include s {
          font-size: 1rem;
        }
        @include xs {
          font-size: .75rem;
        }
      }
    }
  }

  .circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    background: $ternary-color;
    font-size: 1.25rem;
    font-weight: 400;
    aspect-ratio: 1;
    color: $default-icon-color;
    @include s{
      width: 2.4rem;
      height: 2.4rem;
      font-size: 1rem;
    }
    @include xs{
      width: 2.3rem;
      height: 2.3rem;
    }
  }
  .propertyContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: .125rem;

    font-size: 1.25rem;
    line-height: 100%;
    @include s {
      font-size: 1rem;
    }
    @include xs {
      font-size: .75rem;
    }

    .value {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      gap: .25rem;
      text-transform: uppercase;
      text-align: right;
      font-size: 1.25rem;
      line-height: 100%;
      font-weight: 600;
      @include s {
        font-size: 1rem;
      }
      @include xs {
        font-size: .75rem;
      }
      span {
        @include BoldFont;
        font-size: 1.25rem;
        line-height: 100%;
        font-weight: 700;
        @include s {
          font-size: .75rem;
        }
      }
      small {
        font-size: .9rem;
      }
      .percentage {
        color: $primary-color;
      }
    }
    .label {
      font-size: 1.25rem;
      line-height: 100%;
      font-weight: 400;

      @include s {
        font-size: 1rem;
      }
      @include xs {
        font-size: .75rem;
      }
    }
  }
}