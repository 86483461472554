@import "../../../../styles/mainStyles.scss";

.totalInvested {
  display: flex;
  flex-direction: column;
  height: auto;
  width: auto;
  gap: 1rem;
  padding: 1.5rem;
  padding: 1px;
  flex-wrap: wrap;
  background: $primary-gradient;
  position: relative;
  overflow: visible;

  .box {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    padding: 0;
    background: $bg-color;
    border-radius: .5rem;

    .header {
      padding: 1rem;
      width: stretch;
      border-bottom: 1px solid $primary-color;

      @include xs {
        padding: .875rem;
      }
      > span {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        @include MediumFont;
        font-size: 1rem;
        font-weight: 500;
        line-height: 105%;
        letter-spacing: 0em;
        text-align: left;
        @include xs {
          font-size: .875rem;
        }
      }
    }
    .history {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1.875rem;
      flex-grow: 1;
      @include s{
        gap: 1rem;
      }
      @include xs{
        gap: .7rem;
      }

      .column {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: .25rem;
        width: 33.33%;
        padding: 0 .5rem;

        .text {
          @include RegularFont;
          font-size: .75rem;
          font-weight: 400;
          line-height: 100%;
          letter-spacing: 0em;
        }
        .value {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: .5rem;
          @include MediumFont;
          font-size: 2.5rem;
          font-weight: 500;
          line-height: 100%;
          letter-spacing: 0em;
          color: $primary-color;
          width: auto;
          &.loading {
            line-height: normal;
          }
          @include s {
            font-size: 1.8rem;
          }
          @include xs {
            font-size: 1.4rem;
          }

          > span {
            @include RegularFont;
            font-size: 1rem;
            text-transform: uppercase;
            color: $primary-color;
            @include xs {
              font-size: .8rem;
            }
          }
        }

        border-left: 2px solid $gray-fifty;
      }
    }
  }
  .row {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    padding: 1.25rem 1rem;
    justify-content: space-between;
    align-items: flex-start;
    width: stretch;
  }
}

.onBoarding {
  @include s {
    left: unset;
    right: 5%;
  }
  @include xs {
    top: -4.5rem;
  }
}