@import "../../../styles/mainStyles.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  max-width: 30rem;
  padding: 2.5rem 0;

  .header {
    display: flex;
    flex-direction: column;
    gap: .75rem;
    padding: 0 2rem;

    .title {
      @include MediumFont;
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 100%;
      letter-spacing: 0em;
      text-align: center;
    }
  }

  .clarification {
    font-size: 1rem;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 0em;
    text-align: center;
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 1.75rem;
    width: 100%;

    .subForm {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      max-width: 100%;
      padding: 0;
      @include s {
        padding: 0 2rem;
      }

      > input {
        width: auto;
      }

      .row {
        display: flex;
        gap: .75rem;

        @include s {
          flex-wrap: wrap;
        }
      }
    }

    .title {
      @include MediumFont;
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 1.5rem;
      text-align: center;
      text-transform: uppercase;
    }
  }

  .checkboxsContainer {
    display: flex;
    flex-direction: column;
    gap: .75rem;
  }

  .button {
    width: 70%;
    margin: 0 auto;

    &:disabled {
      color: $text-color;
    }
  }
}