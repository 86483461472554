@import '../../styles/mainStyles.scss';

.container {
  display: flex;
  align-items: center;
  width: 100%;
  gap: .625rem;
}
.label {
  @include RegularFont;
  font-size: 1rem;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0em;
  text-align: left;

  &.error {
    color: $error;
  }
}
.box {
  display: flex;
  position: relative;
  border-radius: .5rem;

  box-sizing: border-box;

  &.error {
    box-shadow: inset 0 0 0 1px $error;
  }

  .input {
    width: 1rem;
    height: 1rem;
    &:hover {
      cursor: pointer;
    }
  }
}
.withBorder {
  border: none;
  box-shadow: inset 0 0 0 1px $gray-fifty;

  &:active,
  &:focus,
  &:focus-within,
  &:focus-visible,
  &:hover {
    box-shadow: inset 0 0 0 1px $gray;
  }
}
.background {
  &-primary {
    input[type="checkbox"] {
      accent-color: $primary-color;
    }
  }
  &-secondary {
    input[type="checkbox"] {
      accent-color: $secondary-color;
    }
  }
  &-secondary-light {
    input[type="checkbox"] {
      accent-color: $blue-light;
    }
  }
  &-light {
    input[type="checkbox"] {
      accent-color: $light;
    }
  }
  &-white {
    input[type="checkbox"] {
      accent-color: $white;
    }
  }
  &-transparent {
    input[type="checkbox"] {
      accent-color: transparent;
    }
  }
}