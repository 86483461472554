@import "../../../../styles/mainStyles.scss";

.card {
  padding: 1px;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  background: $primary-gradient;

  .investmentBox {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 1rem;
    width: 100%;

    padding: 1.875rem 2.25rem;
    background: $bg-color;
    border-radius: .625rem;
    @include s {
      gap: .75rem;
      padding: 1.5rem 1.75rem;
    }
    @include xs {
      gap: .5rem;
      padding: 1rem;
    }
    .label {
      text-transform: capitalize;
    }

    .redirect {
      display: flex;
      gap: .5rem;
      align-items: center;
      .buttonRedirect {
        &:hover {
          cursor: pointer;
        }
        svg {
          width: .875rem;

          path {
            fill: $primary-color;
          }
        }
      }
    }

    .line {
      margin: 1rem 0;
      width: 100%;
      border: 1px solid $neutrals5;

      @include s {
        margin: .75rem 0;
      }
      @include xs {
        margin: .5rem 0;
      }
    }

    .footer {
      display: flex;
      flex-direction: column;
      gap: 2rem;

      .button {
        font-size: 1rem;
        height: 2.875rem;
        margin-top: auto;

        @include xs {
          font-size: .875rem;
        }
      }

      @include s {
        gap: 1rem;
      }
    }

  }
}