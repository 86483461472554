@import "../../styles/mainStyles.scss";

.section {
  padding-top: 2rem;

  @include s {
    padding-top: 1rem;
  }
}
.container {
  width: 100%;
  gap: 1.5rem;

  @include s {
    gap: 1rem;
  }
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  .title {
    display: flex;
    flex-direction: column;
    gap: .35rem;
    width: 100%;
    padding-bottom: 1rem;

    .name {
      @include MediumFont;
      font-size: 2.5rem;
      line-height: 100%;
      font-weight: 500;
      text-transform: uppercase;
      @include s {
        font-size: 2rem;
      }
      @include xs{
        font-size: 1.375rem;
      }
    }
    .location {
      @include MediumFont;
      font-size: 1.875rem;
      line-height: 100%;
      font-weight: 500;
      color: $primary-color;
      @include s{
        font-size: 1.5rem;
      }
      @include xs{
        font-size: 1rem;
      }
    }
    @include s {
      padding-bottom: 0;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    margin-left: auto;
    gap: 1.25rem;

    .action {
      display: flex;
      align-items: center;
      > span {
        @include MediumFont;
        font-size: .875rem;
        font-weight: 500;
        line-height: 100%;

        @include s {
          font-size: .75rem;
        }
        @include xs {
          font-size: .625rem;
        }
      }

      &:hover {
        cursor: pointer;
        opacity: .9;
      }
    }
  }
}