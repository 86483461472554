@import "../../../../styles/mainStyles.scss";

.recentTransactions {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  height: auto;
  width: 100%;
  gap: 1rem;
  flex-grow: 1;

  .header {
    display: flex;
    flex-direction: column;
    width: inherit;
    gap: 1rem;

    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 1rem;

      .titleBox {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: .5rem;
        @include MediumFont;
        font-size: 1rem;
        font-weight: 500;
        line-height: 105%;
        letter-spacing: 0em;
        text-align: left;
        color: $secondary-color;

        @include xs {
          font-size: .875rem;
        }

        svg {
          transform: rotate(90deg);
          @include xs {
            height: .75rem;
          }
        }
        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
      .orderButton {
        display: flex;
        align-items: center;
        gap: .75rem;
        @include MediumFont;
        font-size: .875rem;
        line-height: 100%;
        font-weight: 600;

        svg {
          transition: $animation-timing-default;
          @include xs {
            width: .75rem;
          }
          &:hover {
            cursor: pointer;
          }
        }
        &.ascending {
          svg {
            transform: rotate(180deg);
          }
        }
        @include m {
          font-size: .75rem;
        }
      }
      .filters {
        display: flex;
        gap: 1.5rem;

        @include s {
          gap: 1rem;
        }
        @include xs {
          gap: .75rem;
        }

        .filter {
          @include MediumFont;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          padding-bottom: .25rem;

          @include xs {
            font-size: 0.75rem;
          }

          &.selected {
            border-bottom: 3px solid $primary-color;
          }
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
  .cardContainer {
    display: flex;
    width: inherit;
  }
  .card {
    display: flex;
    flex-direction: column;
    padding: 1.625rem 1.75rem;
    width: 100%;
    @include s {
      padding: 1.25rem;
    }
    @include s {
      padding: 1.25rem;
    }
    @include xs {
      padding: .75rem .5rem;
    }

    .table {
      display: flex;
      flex-direction: column;
      height: auto;
      width: 100%;
      flex-grow: 1;
      .body {
        @include m {
          overflow-y: hidden;
          overflow-x: auto;
        }
      }
    }

    .columnHeader, .column {
      width: 15%;
      min-width: 9.5rem;
      &.grow {
        flex-grow: 1;
        width: 40%;
      }
      &:last-child {
        justify-content: flex-end;
        text-align: right;
      }
    }
    .headers {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      gap: .5rem;
      padding-bottom: .75rem;
      border-bottom: 1px solid #43435180;

      @include l {
        min-width: fit-content;
      }

      .columnHeader {
        @include MediumFont;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: 104%;
        color: $gray-fifty;
        text-align: left;

        &:last-child {
          text-align: right;
          padding-right: 1.5rem;
          @include m {
            padding-right: 0;
          }
        }

        @include s {
          font-size: .75rem;
        }
      }
    }
    .transaction {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      gap: .5rem;
      padding: 1rem 0;
      border-bottom: 1px solid #43435180;

      @include l {
        min-width: fit-content;
      }
      &:last-child {
        border-bottom: none;
      }
      @include s {
        padding: .75rem 0;
      }
      .column {
        @include RegularFont;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: 104%;
        color: $gray-fifty;
        @include s {
          font-size: .875rem;
        }
      }
      .type {
        display: flex;
        align-items: center;
        font-weight: 600;
        color: $text-color;
        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 .5rem;
        }
      }
      .amount {
        margin-left: auto;
        @include MediumFont;
        font-size: 1rem;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: 0em;
        color: $secondary-color;
        white-space: nowrap;

        &.green {
          color: $success;
        }
        &.red {
          color: $red;
        }
        &.blue {
          color: $secondary-color;
        }
        &.yellow {
          color: $primary-color;
        }
      }
    }
    .seeMore {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: .25rem;
      text-transform: lowercase;
      font-size: .875rem;
      font-weight: 400;
      line-height: 100%;
      letter-spacing: 0em;
      color: $text-color;
      padding-top: .5rem;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }

      svg {
        width: .5rem;
      }
    }

    .emptyText {
      @include MediumFont;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: .875rem;
      font-weight: 500;
      line-height: 100%;
      letter-spacing: 0em;
      color: $gray-fifty;
      text-align: center;
      max-width: 10rem;
      flex-grow: 1;
      margin: 1.5rem auto 1rem;
    }
  }
}