@import "../../../../styles/mainStyles.scss";

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .label {
    @include MediumFont;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: 0em;

    @include s {
      font-size: 1rem;
    }
    @include xs {
      font-size: 0.875rem;
    }
  }
  .counter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 1rem;

    > span {
      font-size: 1rem;
      font-weight: 400;
      line-height: 100%;
      letter-spacing: 0em;
      color: $gray-fifty;
      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10 and IE 11 */
      user-select: none; /* Standard syntax */

      @include s {
        font-size: .75rem;
      }
      @include xs {
        font-size: 0.625rem;
      }
    }
    @include xs {
      gap: 0.75rem;
    }
  }
}