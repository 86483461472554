@import "../../styles/mainStyles.scss";

.modal {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;

  padding: 2rem 0;
  margin: 0 auto;

  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  background: rgba($dark-light, .8);
  opacity: 0;

  animation: showModal 0.5s forwards;

  @include s {
    padding: 0 .5rem;
    max-width: stretch;
  }
}

@keyframes showModal {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.outer {
  position: relative;
  margin: auto;

  padding: 2.5rem 1.25rem 1.125rem;

  width: fit-content;
  background: $bg-color;
  box-shadow: 0 5px 10px $shadow-color;
  border-radius: .625rem;

  @include xs {
    padding: 2rem 1rem 1rem;
  }
}

.container {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  > svg {
    padding-bottom: .75rem;
  }
  .title {
    @include RegularFont;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: 0em;
    text-align: center;

    @include xs {
      font-size: 1rem;
    }
  }
  .subtitle, .extraDescription {
    @include RegularFont;
    font-size: .875rem;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 0em;
    text-align: center;

    @include xs {
      font-size: .75rem;
    }
  }
  .extraDescription {
    padding-top: 1rem;
  }
  .button {
    margin-top: .75rem;
    width: 100%;
  }
}

.close {
  border: none;
  background: none;

  position: absolute;
  top: 1.75rem;
  right: 1.75rem;
  top: -1rem;
  right: -1rem;

  &.onLeft {
    right: unset;
    left: 1.75rem;
  }

  svg {
    transition: transform 0.2s;
    fill: $dark-medium;
  }

  &:hover {
    cursor: pointer;
    svg {
      path {
        fill: $dark-medium;
      }
    }
  }
}
