@import '../../styles/mainStyles.scss';

.section {
  position: relative;
}
.container {
  width: stretch;
  margin: -7rem auto 1rem auto;

  @include s {
    margin: -6rem auto 0 auto;
  }
  @include xs {
    margin: -4rem auto 0 auto;
  }

  &.second {
    margin: 0 auto;
    padding-top: 0;
  }
}
.wrapContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  width: stretch;
  border-radius: .625rem;
  padding: 2.5rem 6rem;

  @include l {
    padding: 2.5rem 4rem;
  }
  @include m {
    padding: 2rem 3rem;
  }
  @include s {
    flex-direction: column;
    padding: 1.875rem 1.5rem;
  }

  &.second {
    flex-direction: column;
    gap: 2rem;
    border-radius: 0;
  }
}
.textWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 33%;

  @include s {
    width: 100%;
    gap: .75rem;
  }
  @include xs {
    gap: .5rem;
  }

  &.second {
    width: 100%;
    text-align: center;
  }
}
.title {
  @include bold-title;
  color: $bg-color;
}
.description {
  @include description;
  color: $bg-color;
  span {
    @include BoldFont;
    color: $primary-color;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: -0.02em;
  }

  font-size: 1.875rem;
  @include xl {
    font-size: 1.75rem;
  }
  @include m {
    font-size: 1.625rem;
  }
  @include s {
    font-size: 1.5rem;
  }
  @include xs {
    font-size: 1rem;
  }
}
.border {
  width: 100%;
  border: 1px solid $light-twenty-five;
  margin: 1rem .5rem 1rem 0;

  @include m {
    margin: 1rem 0;
  }
  @include s {
    margin: .75rem 0;
  }
  @include xs {
    margin: .5rem 0;
  }
}
.footer {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.375rem;
  letter-spacing: -0.02em;
  color: $bg-color;
  @include s {
    display: none;
  }
}
.slider {
  width: 58.5%;
  @include m {
    width: 63%;
  }
  @include s {
    width: 100%;
  }

  .swiperSlide {
    overflow: hidden;
    border-radius: .5rem;
  }
  .slide {
    display: flex;
    justify-content: space-between;
    transition: .5s;
    width: 100%;
  }
  .bullet {
    background: $light-twenty-five !important;
  }
  .bulletActive {
    background: $light !important;
  }

  &.second {
    width: 100%;
    .swiperSlide, .slide {
      border-radius: 0;
    }
  }
  &.straightCorners {
    .slide, .swiperSlide {
      border-radius: 0;
    }
  }
}

.second {
  border-radius: 0;
}