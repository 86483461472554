@import "../../../styles/mainStyles.scss";

.header {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  .title {
    @include MediumFont;
    font-size: 2.5rem;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: center;
    font-style: normal;
    line-height: 104%;

    @include s {
      font-size: 1.75rem;
    }
    @include xs {
      font-size: 1.25rem;
      width: 80%;
      margin: 0 auto;
    }
  }
  .subtitle {
    font-size: 1rem;
    font-weight: 400;
    font-style: normal;
    text-align: center;
    line-height: 104%; /* 1.04rem */
  }
  .bought {
    @include RegularFont;
    font-size: 1rem;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 0em;
    text-align: center;
  }
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: .5rem 0 1rem;
  gap: 1.25rem;
  width: 100%;
  max-width: 25rem;
  margin: 0 auto;

  .row, .projectData {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5rem;
    @include RegularFont;
    font-size: 1.875rem;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 0em;
    width: 100%;

    .inputContainer {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      gap: .25rem;

      &.error {
        > span {
          color: $error;
          font-weight: 600;
        }
      }

      .input {
        display: flex;
        @include MediumFont;
        font-size: 3.75rem;
        font-weight: 600;
        letter-spacing: 0em;
        flex-grow: 1;
        height: 4rem;
        width: 55%;
        max-width: 55%;
        text-align: end;
        box-shadow: none;
        border: none;
        outline: none;
        background: none;
        position: relative;

        &:active,
        &:focus,
        &:focus-within,
        &:focus-visible,
        &:hover {
          border: none;
          box-shadow: none;
          outline: none;
        }
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
          /* display: none; <- Crashes Chrome on hover */
          -webkit-appearance: none;
          margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
      }
    }
  }
  .line {
    border: 1px solid $primary-color;
    width: 100%;
  }
  .available {
    @include MediumFont;
    font-size: .875rem;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: 0em;

    > span {
      @include RegularFont;
      font-weight: 500;
      margin-right: .25rem;
    }

    @include xs {
      padding-bottom: 3rem;
    }
  }
}

.buttons {
  display: flex;
  gap: 1rem;
  width: 100%;
  .actionButton {
    flex-grow: 1;
    box-shadow: inset 0 0 0 2px $primary-color;
    gap: .5rem;
    font-size: 1rem;
  }
}

.details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 1rem;
  width: 100%;

  .subtitle {
    @include MediumFont;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 104%; /* 1.04rem */
    width: 100%;
    margin-bottom: .5rem;
  }
  .row {
    display: flex;
    justify-content: space-between;

    span {
      @include RegularFont;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 104%; /* 1.04rem */

      &.bold {
        @include MediumFont;
        font-size: 1.25rem;
        font-weight: 700;
      }
    }
  }
  .line {
    border: 1px solid rgba(67, 67, 81, 0.50);
    width: 100%;
    margin: .5rem 0;
  }
}