@import "../../styles/mainStyles.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  width: stretch;

  .card {
    &.error {
      border: 1px solid $red;
    }

    .uploader {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      gap: .5rem;
      padding: 2.5rem 0;

      &:hover {
        cursor: pointer;
      }

      .text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: .25rem;

        .title {
          @include MediumFont;
          font-size: 0.75rem;
          font-style: normal;
          font-weight: 400;
          line-height: 104%; /* 0.78rem */
          color: $neutrals5;
        }
        .format {
          font-size: 0.5625rem;
          font-style: normal;
          font-weight: 400;
          line-height: 104%; /* 0.585rem */
          color: $neutrals5;
        }
      }
    }
  }
  .errorMessage {
    @include RegularFont;
    font-size: 0.5625rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: $red;
    text-align: left;
    visibility: hidden;

    &.active {
      visibility: visible;
    }
  }
}