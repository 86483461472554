@import "../../../../styles/mainStyles.scss";

.container {
  display: flex;
  justify-content: space-between;
  gap: 1.25rem;
  padding: 1rem 0 3rem;

  @include m {
    flex-direction: column;
  }
  @include s {
    padding: .75rem 0 2rem;
  }
  @include s {
    padding: .5rem 0 1rem;
  }
  .firstColumn, .secondColumn {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 50%;
    @include m {
      width: 100%;
    }

    .infoHeader {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding-bottom: 1rem;

      @include s{
        gap: .75rem;
        padding-bottom: .75rem;
      }
      @include xs{
        gap: .5rem;
        padding-bottom: .5rem;
      }

      .title {
        @include MediumFont;
        font-size: 1.875rem;
        line-height: 1.875rem;
        font-weight: 600;
        @include s{
          font-size: 1.5rem;
          line-height: 1.5rem;
        }
        @include xs{
          font-size: 1.2rem;
          line-height: 1.2rem;
        }
      }
      .subtitle {
        @include MediumFont;
        font-size: 1.25rem;
        line-height: 1.25rem;
        font-weight: 500;
        @include s{
          font-size: 1.1rem;
          line-height: 1.1rem;
        }
        @include xs{
          font-size: 1rem;
          line-height: 1rem;
        }
      }
    }
  }

  .firstColumn {
    .card {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      padding: 2rem;
      height: auto;
      width: auto;

      @include m {
        gap: 1.75rem;
        padding: 0;
        border-radius: 0;
      }
      @include s {
        gap: 1.5rem;
      }
      @include xs {
        gap: 1rem;
      }
      .boxes {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 1rem;
        width: 100%;
        height: 100%;

        .box, .highlight {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-radius: .625rem;
          color: $icon-color;
          max-width: calc(25% - .75rem);
          flex: 0 0 calc(25% - .75rem);
          height: 100%;
          max-height: 7.125rem;
          gap:.5rem;

          @include m {
            min-height: 6.125rem;
            max-height: 7rem;
          }
          @include s {
            max-height: 6.625rem;
          }
          @include xs {
            height: 4.3125rem;
          }

          .value {
            font-size: 2.5rem;
            line-height: 100%;
            font-weight: 400;
            @include l {
              font-size: 2.25rem;
            }
            @include m {
              font-size: 2rem;
            }
            @include s {
              font-size: 1.75rem;
            }
            @include xs{
              font-size: 1.25rem;
            }
          }
          .label {
            font-size: 1.5rem;
            font-weight: 400;
            line-height: 100%;
            letter-spacing: 0em;
            text-align: center;
            @include s{
              font-size: 1rem;
            }
            @include xs{
              font-size: .5rem;
            }
          }
          span {
            font-size: .875rem;
            line-height: 100%;
            font-weight: 400;
            text-align: center;

            @include s {
              font-size: .75rem;
            }
            @include xs {
              font-size: .625rem;
            }
          }
        }
        .box {
          aspect-ratio: 1 / 1;
          background: $ternary-color;
        }
        .highlight {
          max-width: calc(50% - .5rem);
          flex: 0 0 calc(50% - .5rem);
          background: $text-color;
          aspect-ratio: 2 / 1;
          color: $light;
        }
        .skeletonBox {
          background: unset;
          span {
            width: 100%;
            height: 100%;
          }
        }
      }
      .line {
        border: 1px solid $neutrals5;
        width: 100%;
        margin: .5rem 1rem 0;

        @include m {
          display: none;
        }
      }
      .description {
        font-weight: 400;
        line-height: 100%;
        text-align: justify;
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */

        @include s {
          font-size: .75rem;
        }
        @include xs {
          font-size: .75rem;
        }
      }
    }
  }

  .secondColumn {
    .infoHeader {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      position: relative;
      height: auto;

      .column {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        @include s{
          gap: .75rem;
        }
        @include xs{
          gap: .5rem;
        }
      }

      .percentage {
        transform: scale(1.6);
        margin-right: 1rem;
        @include s{
          margin-right: .5rem;
          transform: scale(1.4);
        }
        @include xs{
          margin-right: .375rem;
          transform: scale(1.2);
        }
        span {
          font-size: .875rem;
        }
      }
    }
    .buttonRow {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      gap: .5rem;

      .linkButton {
        width: 50%;
        text-decoration: none;
        color: unset;

        .button {
          padding: 0;
          border-radius: .5rem;
          gap: .25rem;
          width: 100%;
          @include RegularFont;

          @include xs {
            font-size: .75rem;
          }

          .icon {
            path {
              stroke: $text-color;
            }
          }
        }
      }

    }

  }
}

.kycModal {
  gap: 1.25rem;
}