@import "../../../../styles/mainStyles.scss";

.container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 1.25rem;

  .cardsContainer {
    display: flex;
    gap: 1.25rem;

    .body {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      gap: 1.25rem;
      padding: 1rem;
      width: 50%;
      height: auto;
      @include s {
        padding: .75rem;
        gap: 1rem;
      }
      @include xs {
        gap: .75rem;
      }

      .title {
        @include MediumFont;
        font-size: 1.25rem;
        font-weight: 600;
        line-height: 100%;

        @include s {
          font-size: 1rem;
        }
        @include xs {
          font-size: .75rem;
        }
      }
      .characteristicsRow {
        display: flex;
        flex-direction: column;
        gap: .5rem;
        width: 100%;

        @include xs {
          gap: .25rem;
        }

        .characteristic {
          display: flex;
          width: 100%;
          justify-content: space-between;
          padding-bottom: .5rem;
          border-bottom: 1px solid $neutrals5;

          @include RegularFont;
          font-size: .875rem;
          font-weight: 400;
          line-height: 100%;

          span {
            @include BoldFont;
            margin-left: auto;
            text-transform: uppercase;
          }

          @include s {
            padding-bottom: .375rem;
            font-size: .75rem;
          }
          @include xs {
            font-size: .625rem;
          }
        }
      }
    }
  }

  .buttonRow {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    gap: .5rem;

    .linkButton {
      width: 48.5%;
      text-decoration: none;
      color: unset;
      @include xs{
        // width: 100%;
      }

      .button {
        padding: 0;
        border-radius: .5rem;
        gap: .25rem;
        @include RegularFont;
        font-weight: 500;
        width: 100%;
        @include xs {
          font-size: .75rem;
        }
        span {
          @include RegularFont;
          font-weight: 500;
        }
        .icon {
          path {
            stroke: $secondary-color;
          }
        }
      }
    }
  }

}