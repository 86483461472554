@import "../../styles/mainStyles.scss";


.container {
  padding: 3rem 0;
  max-width: 28.625rem;
  gap: 1.25rem;
  height: 70vh;
  width: 100%;

  @include xs {
    height: auto;
    padding: 3rem 1.5rem;
  }
}
.form {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  .button {
    margin: 0 auto;
    width: 100%;
    max-width: 25rem;
  }
}
.fundsModal {
  max-width: 22rem;
}
.pending {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.25rem;

  > svg {
    path {
      stroke: $primary-color;
    }
  }

  > span {
    @include RegularFont;
    text-align: center;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 104%;
    text-transform: uppercase;
    color: $primary-color;
  }
}