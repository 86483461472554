@import "../../styles/mainStyles.scss";

.section {
  padding: 1.5rem 0;
}

.container {
  align-items: center;
  justify-content: center;
  width: 100%;
}

.noPadding {
  padding: 0;
}