@import "../../../../styles/mainStyles.scss";

.mainContainer {
  position: relative;
}

.navigator, .main, .secondary {
  display: flex;
  gap: 1.25rem;
  position: relative;
  // transition: 2s;
  @include s{
    gap: 0;
  }

  .main {
    // transition: 2s;
    position: relative;
    // width: 70%;
    width: auto;
    height: 100%;
    max-height: 42.5rem;
    height: 42.5rem;
    flex-grow: 1;
    border-radius: .625rem;
    overflow: hidden;

    img {
      transition: .5s;
      width: 100%;
    }

    .itemImage {
      transition: background-image 0.2s ease-in-out;
      width: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      border-radius: .625rem;
      @include s{
        display: none;
      }
    }

  }
  .desktopImage {
    @include s{
      width: 0%;
      flex-grow: 0;
    }
  }

  .secondary {
    display: none;
    flex-direction: column;
    align-content: space-between;
    justify-content: space-between;
    // flex-grow: 1;
    // height: 100%;

    img {
      // width: 100%;
      // width: 18.438rem;
    }

    &.visible {
      display: flex;
    }


    .slider {
      padding: 0;
      width: 18.438rem;
      height: 100%;
      aspect-ratio: 1 / 1 ;
      @include s{
        width: calc(100vw - 4rem);
      }
      @include xs{
        width: calc(100vw - 3rem);
      }
      // overflow: visible !important;
      // @include s {
      //   overflow: hidden !important;
      // }
    }

    .swiperSlide {
      overflow: hidden;
      border-radius: .5rem;
    }

    .slide {
      transition: .5s;
      object-fit: cover;
      // width: 18.438rem;
      width: 100%;

      // @include l {
      //   height: 16.423rem !important;
      // }
      // @include m {
      //   height: 15.423rem !important;
      // }
      // @include s {
      //   height: 13.4rem !important;
      // }

      &:hover {
        cursor: pointer;
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
      }
    }

    .prevArrow, .nextArrow {
      position: absolute;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      z-index: 5;
      @include s{
        width: auto;
        rotate: 270deg;
      }

      .box {
        transition: $animation-timing-default;
        padding: .625rem .5rem;
        border-radius: .5rem;
        background: $light;

        &:hover {
          cursor: pointer;
          background: darken($light, 2);
        }
      }
    }
    .prevArrow {
      top: .5rem;
      @include s{
        top: 50%;
        left: .5rem;
      }
    }
    .nextArrow {
      bottom: .5rem;
      @include s{
        top: 50%;
        right: .5rem;
        bottom: auto;
      }
    }
  }
}
.forceLeft {
  right: 0 !important;
}
.navigateButtonsRow {
  position: absolute;
  display: flex;
  align-items: flex-end;
  gap: .25rem;
  bottom: 0;
  right: 19.638rem;
  margin: .75rem;
  z-index: 1;
  @include s {
    right: 0;
  }

  .navigateButton {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    align-items: center;
    justify-self: flex-end;
    padding: 1.25rem 0 .5rem;
    width: 5rem;
    height: 100%;
    background: $bg-color;
    border-radius: .5rem;
    transition: $animation-timing-default;

    @include MediumFont;
    font-size: .875rem;
    line-height: .875rem;
    font-weight: 500;

    @include s {
      padding: .5rem 0;
    }

    svg {
      display: none;
      path {
        stroke: $primary-color;
      }
    }

    &.active {
      svg {
        display: block;
        @include s {
          display: none;
        }
      }
      @include s {
        background: $primary-color;
        color: $light;
      }
    }
    &.disabled {
      display: none;
    }
    &:hover {
      cursor: pointer;
      background: $light;
    }
  }
}