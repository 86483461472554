@import '../../styles/mainStyles.scss';

.section {
  // position: relative;
  background: linear-gradient(97.18deg, #D9D9D9 4.29%, #D9D9D9 99.11%);

  &.second {
    background: none;
    background-image: url('../../assets/images/action-list-bg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 12.1875rem;
  }
}
.container {
  gap: 2.5rem;
  width: 100%;
  padding-top: 2.5rem;
  padding-bottom: 4rem;

  @include m {
    gap: 2.25rem;
  }
  @include s {
    padding-top: 2rem;
    gap: 2rem;
  }
  @include xs {
    padding-top: 1.75rem;
    gap: 1.875rem;
  }

  &.second {
    justify-content: center;
    padding-top: 0;
    padding-bottom: 0;
  }
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.25rem;

  &.second {
    justify-content: center;
  }

  .title {
    @include bold-title;

    &.second {
      br { display: none;}
      color: $bg-color;
      text-align: center;
    }
  }
}
.actionRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;

  .actionCard {
    max-width: calc(25% - .75rem);
    flex: 0 0 calc(25% - .75rem);
    aspect-ratio: 1 / 1 ;
    background: $white;
    @include s {
      max-width: calc(50% - .75rem);
      flex: 0 0 calc(50% - .75rem);
    }
    @include xs {
      max-width: calc(50% - .5rem);
      flex: 0 0 calc(50% - .5rem);
    }
    @include xxs {
      max-width: calc(100%);
      flex: 0 0 calc(100%);
    }
  }
}