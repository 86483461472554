@import "../../../../styles/mainStyles.scss";

.recentTransactions {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  height: auto;
  width: 100%;
  gap: 1rem;
  flex-grow: 1;

  > span {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    @include MediumFont;
    font-size: 1rem;
    font-weight: 500;
    line-height: 105%;
    letter-spacing: 0em;
    text-align: left;
    color: $secondary-color;
  }
  .body {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    flex-grow: 1;

    .transaction {
      display: flex;
      align-items: center;
      gap: .75rem;
      padding: .25rem 0 .5rem;
      border-bottom: 1px solid #43435180;

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 .5rem;
      }
      .text {
        display: flex;
        flex-direction: column;
        gap: .5rem;
        .description {
          font-size: .875rem;
          font-weight: 400;
          line-height: 100%;
          letter-spacing: 0em;
          text-align: left;
        }
        .date {
          font-size: .75rem;
          font-weight: 400;
          line-height: 100%;
          letter-spacing: 0em;
          text-align: left;
          color: $gray-fifty;
        }
      }
      .value {
        margin-left: auto;
        @include MediumFont;
        font-size: 1rem;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: 0em;
        color: $secondary-color;

        &.green {
          color: $success;
        }
        &.red {
          color: $red;
        }
        &.blue {
          color: $secondary-color;
        }
        &.yellow {
          color: $primary-color;
        }
      }
    }
    .seeMore {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: .25rem;
      text-transform: lowercase;
      font-size: .875rem;
      font-weight: 400;
      line-height: 100%;
      letter-spacing: 0em;
      color: $text-color;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }

      svg {
        width: .5rem;
      }
    }

    .emptyText {
      @include MediumFont;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: .875rem;
      font-weight: 500;
      line-height: 100%;
      letter-spacing: 0em;
      color: $gray-fifty;
      text-align: center;
      max-width: 10rem;
      flex-grow: 1;
      margin: 0 auto;
    }
  }
}