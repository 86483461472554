@import './colors.scss';
@import './fonts.scss';
@import './breakpoints.scss';
@import './brand-blocks.scss';

$default-button-border-radius: $button-border-radius;

html,
body {
  margin: 0;
  height: fit-content;
  min-height: 100vh;
  @include RegularFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
}
code {
  @include RegularFont;
}

@mixin title {
  @include RegularFont;
  font-style: normal;
  font-weight: 700;
  font-size: 2.75rem;
  line-height: 90%;
  line-height: 100%;
  letter-spacing: -0.02em;

  @include xl {
    font-size: 2.625rem;
  }
  @include l {
    font-size: 2.5rem;
  }
  @include m {
    font-size: 2.325rem;
  }
  @include s {
    font-size: 2.25rem;
    line-height: 120%;
  }
  @include xs {
    font-size: 2.125rem;
  }
}

@mixin bold-title {
  @include BoldFont;
  font-style: normal;
  font-weight: 700;
  font-size: 2.75rem;
  line-height: 100%;

  @include xl {
    font-size: 2.625rem;
  }
  @include l {
    font-size: 2.5rem;
  }
  @include m {
    font-size: 2.25rem;
  }
  @include s {
    font-size: 2rem;
  }
  @include xs {
    font-size: 1.375rem;
  }
}

@mixin description {
  @include RegularFont;
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 120%;

  @include xl {
    font-size: 1.375rem;
  }
  @include l {
    font-size: 1.125rem;
  }
  @include m {
    font-size: 1rem;
  }
  @include s {
    font-size: .9rem;
  }
  @include xs {
    font-size: .875rem;
  }
}

.dark-background {
  background: $dark;
}
.primary-background {
  background: $primary-color;
}
.secondary-background {
  background: $secondary-color;
}
.ternary-background {
  background: $ternary-color;
}
.dim {
  animation: fadeIn .5s;
  -webkit-animation: fadeIn .5s;
  /* For Internet Explorer */
  box-shadow: 0 0 0 1000px rgba(0, 0, 0, .3) !important;
  /* For other browsers */
  box-shadow: 0 0 0 100vmax rgba(0, 0, 0, .3) !important;
  pointer-events: none;
  z-index: 500;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-moz-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-webkit-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-o-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-ms-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}