@import '../../styles/mainStyles.scss';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: .625rem;
}
.label {
  @include MediumFont;
  font-size: 1rem;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0em;
  text-align: left;

  &.error {
    color: $error;
  }
}
.box {
  display: flex;
  position: relative;
  border-radius: .5rem;
  height: 3.125rem;
  padding: 0 1rem;

  box-sizing: border-box;

  .input {
    display: flex;
    @include RegularFont;
    font-weight: 400;
    font-size: 1rem;
    flex-grow: 1;
    box-shadow: none;
    border: none;
    outline: none;
    background: none;
    position: relative;

    &:active,
    &:focus,
    &:focus-within,
    &:focus-visible,
    &:hover {
      border: none;
      box-shadow: none;
      outline: none;
    }
  }

  svg {
    position: absolute;
    right: .75rem;
    height: 100%;
    &:hover {
      cursor: pointer;
    }
  }

  &.error {
    box-shadow: inset 0 0 0 1px $error !important;
  }
}
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
}
.withBorder {
  border: none;
  box-shadow: inset 0 0 0 1px $gray-fifty;

  &:active,
  &:focus,
  &:focus-within,
  &:focus-visible,
  &:hover {
    box-shadow: inset 0 0 0 1px $gray;
  }
}
.background {
  &-primary {
    color: $light;
    background: $primary-color;
  }
  &-secondary {
    color: $light;
    background: $secondary-color;
  }
  &-light {
    background: $light;
  }
  &-white {
    background: white;
  }
  &-transparent {
    background: transparent;
  }
}