@import "../../../../styles/mainStyles.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  position: relative;
  text-align: center;

  max-width: 25rem;
  margin: 0 auto;

  @include s {
    gap: 1.25rem;
  }
  @include xs {
    gap: 1rem;
  }

  .title {
    @include MediumFont;
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 104%;
    letter-spacing: 0em;
    text-align: center;

    @include s {
      font-size: 1.75rem;
    }
    @include xs {
      font-size: 1.25rem;
    }
  }
  .price {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    span {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: .5rem;

      .value {
        @include BoldFont;
        font-size: 3.75rem;
        font-weight: 600;
        line-height: 130%;
        letter-spacing: 0em;
      }
      .currency {
        @include RegularFont;
        font-size: 1.875rem;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: 0em;
        text-transform: uppercase;
        margin-top: .5rem;
      }
    }
    .officialCurrency {
      @include RegularFont;
      font-size: 1rem;
      font-weight: 400;
      line-height: 105%;
      letter-spacing: 0em;
    }
  }
  .properties {
    display: flex;
    flex-direction: column;
    gap: .75rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid $neutrals5;

    .property {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      @include RegularFont;
      font-size: 1rem;
      font-weight: 400;
      line-height: 110%;
      letter-spacing: 0em;

      span {
        @include BoldFont;
        font-weight: 600;
      }
    }
  }
  .paymentMethod {
    display: flex;
    flex-direction: column;
    gap: .75rem;

    .label {
      @include BoldFont;
      font-size: 1rem;
      font-weight: 600;
      line-height: 110%;
      letter-spacing: 0em;
      text-align: start;
    }
    .method {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      @include RegularFont;
      font-size: 1rem;
      font-weight: 400;
      line-height: 100%;
      letter-spacing: 0em;

      span {
        display: flex;
        gap: .25rem;
        align-items: center;

        .circle {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 1.5rem;
          height: 1.5rem;
          border-radius: 100%;

          svg {
            width: .875rem;
            height: .875rem;
            transform: scale(1);
          }
        }
      }
      .changeMethod {
        display: flex;
        gap: .25rem;
        font-size: .875rem;
        align-items: center;
        color: $primary-color;

        &:hover {
          cursor: pointer;
          color: darken($primary-color, 2);
          text-decoration: underline;
        }
      }
    }
  }
  .button {
    margin-top: 1rem;
  }
}

.errorPaymentModal {
  span {
    width: 60%;

    @include xs {
      width: 70%;
    }
  }
}