@import '../../../../styles/mainStyles.scss';

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 1rem;
  height: fit-content;
  overflow: hidden;
  padding: 0 1.75rem;

  svg {
    @include m {
      width: 60%;
      height: 60%;
    }
    @include s {
      width: 50%;
      height: 50%;
    }
    @include xs {
      width: 50%;
      height: 50%;
    }
    path {
      stroke: $primary-color;
    }
  }

  @include l {
    padding: 0 1.5rem;
    gap: .75rem;
  }
  @include m {
    padding: 0 1rem;
    gap: .5rem;
  }
  @include s {
    padding: 0 .75rem;
  }
  @include xs {
    gap: .375rem;
    padding: 0 .5rem;
  }
}

.action {
  @include MediumFont;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 100%;

  @include m {
    font-size: 1rem;
  }
  @include xs {
    font-size: .75rem;
  }
}
.description {
  @include RegularFont;
  font-size: .875rem;
  font-weight: 500;
  line-height: 100%;

  @include m {
    font-size: .75rem;
  }
  @include xs {
    font-size: .625rem;
  }
}