@import "../../../../styles/mainStyles.scss";

.wrapper {
	box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.3);
	border-radius: 100%;
}

.singleChart {
  width: 3rem;
  justify-content: space-around;
}

.circularChart {
  display: block;
  margin: auto;
}

.circleBg {
  fill: white;
  stroke: white;
  stroke-width: 3.8;
}

.circle {
  stroke-width: 4.2;
  stroke-linecap: round;
  animation: progress 1s cubic-bezier(0.4, 0, 0.2, 1) forwards;


}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

.circularChart.primary .circle {
  stroke: $primary-color;
  fill: none;
  &.empty {
    stroke: none;
  }
  &.completed {
    fill: $primary-color;
  }
}

.circularChart.secondary .circle {
  stroke: $secondary-color;
  fill: none;
  &.empty {
    stroke: none;
  }
  &.completed {
    fill: $secondary-color;
  }
}

.percentage {
	@include BoldFont;
  font-size: 0.6em;
  text-anchor: middle;
	font-weight: 700;
	fill: $gray;

  &.completed {
    fill: $light;
  }
}