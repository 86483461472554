@font-face {
  font-family: 'RegularFont';
  src: url('../assets/fonts/Saira-Regular.ttf');
}
@mixin RegularFont {
  font-family: RegularFont;
}
@font-face {
  font-family: 'MediumFont';
  src: url('../assets/fonts/Saira-Medium.ttf');
}
@mixin MediumFont {
  font-family: MediumFont;
}
@font-face {
  font-family: 'BoldFont';
  src: url('../assets/fonts/Saira-Bold.ttf');
}
@mixin BoldFont {
  font-family: BoldFont;
  @include xs {
    font-family: MediumFont;
  }
}
@font-face {
  font-family: 'ExtraBoldFont';
  src: url('../assets/fonts/Saira-ExtraBold.ttf');
}
@mixin ExtraBoldFont {
  font-family: ExtraBoldFont;
}
@font-face {
  font-family: 'InterRegular';
  src: url('../assets/fonts/Inter-Regular.ttf');
}
@mixin InterRegular {
  font-family: InterRegular;
}